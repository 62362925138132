import { GraphQLClient } from "graphql-request";
import axios from "axios";

export default class Datalayer {

    async postql(query, variables) {
        const client = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL + '/graphql', { headers: {} })
        return client.request(query, variables)
            .then(data => {
                return data;
            })
            .catch(err => {
                return 0;
            })
    }

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    get(path) {
        
        var instance = axios.create({
            baseURL: process.env.NEXT_PUBLIC_API_URL + '/api',
            timeout: 60000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
                'Expires': '-1'
            },
            validateStatus: function () {
                return true;
            }
        });

        instance.interceptors.response.use(undefined, () => {
            //console.log(err);
        });

        return new Promise(function (resolve) {
            instance.get(path).then(function (response) {
                resolve(response);
            }).catch(function (error) {
                resolve(error);
            });
        });

    }

    post(path, params) {
        var instance = axios.create({
            baseURL: process.env.NEXT_PUBLIC_API_URL + '/api/',
            timeout: 60000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
                'Content-Type': 'application/json; charset=utf-8',
                'Expires': '-1'
            },
            validateStatus: function () {
                return true;
            }
        });

        instance.interceptors.response.use(undefined, () => {
            //console.log(err);
        });

        return new Promise(function (resolve) {
            instance.post(path, params).then(function (response) {
                resolve(response);
            }).catch(function (error) {
                resolve(error);
            });
        });
    }
}