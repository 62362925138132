import "../styles/globals.css";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../utilities/error";
import Database from "../utilities/database";
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import _ from "lodash";
import { GoogleAnalytics } from "nextjs-google-analytics";
import PouchDB from "pouchdb";
import { Provider } from "use-pouchdb";
import tw, { GlobalStyles, styled } from "twin.macro";
import { RecoilEnv, RecoilRoot } from "recoil";
import { firebaseCloudMessaging } from "./../utilities/firebase";
import { useCustomState } from "./../state/cart";

PouchDB.plugin(require('pouchdb-upsert'));

const Header = dynamic(() => import("./../components/header"));
const Footer = dynamic(() => import("./../components/footer"));
const Sidebar = dynamic(() => import("./../components/nav/sidebar"));
const isBrowser = typeof window !== "undefined";

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

function DelForno({ Component, pageProps }) {

    let db = new Database();
    var localDB = db.getDatabase();
    const { asPath } = useRouter();
    const showHeader = (asPath === "/address") ? false : true;
    const [state, actions] = useCustomState();

    const [mounted, setMounted] = useState(false);
    const [loaded, setLoaded] = useState(false);

    if (mounted) {
        firebaseCloudMessaging.onMessage();
    }

    useEffect(() => {

        if (!loaded) {

            try {

                db.putCustomer();
                db.putHistory();
                db.putBasePrices();
                db.putBaseIngredients();
                db.putBaseOptions();
                db.putBaseSpecials();
                db.putCart();

                db.replicateDatabase();
                setLoaded(true);
            } catch (error) {
                console.log('DB:', error);
                setLoaded(false);
            }
        }

    }, [loaded, db]);

    useEffect(() => {

        function clearOldCart() {
            actions.clearOldCart();
        }

        clearOldCart();

    }, [actions]);

    //select an item randomly from the menu to add an easter egg
    useEffect(() => {

        let _egg = localStorage.getItem('_id');

        if (_egg === null) {
            db.getMenuItems().then((menu) => {
                const randomItem = _.sample(menu.menuitems);
                if (randomItem) {
                    localStorage.setItem('_id', randomItem.menuItemId);
                }
            });
        }

    }, [db]);

    useEffect(() => {

        async function getFirebaseToken() {
            await firebaseCloudMessaging.init();
            const token = await firebaseCloudMessaging.tokenInlocalforage();
            if (token) setMounted(true);
        }

        getFirebaseToken();

    }, []);


    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const utmId = urlParams.get('utm_id');

        if (utmId) {
            localStorage.setItem('utm_id', utmId);
        } else {
            localStorage.setItem('utm_id', '');
        }

    }, []);

    return <>
        <ErrorBoundary>
            <GoogleAnalytics trackPageViews />
            <RecoilRoot>
                <Provider pouchdb={localDB}>
                    <Head>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    </Head>
                    <GlobalStyles />
                    {showHeader && <><PageContainer>
                        <HeaderContainer>
                            <Header />
                        </HeaderContainer>
                        <BodyContainer>
                            <Component {...pageProps} />
                        </BodyContainer>
                        <FooterContainer>
                            <Footer />
                        </FooterContainer>
                        <Sidebar /></PageContainer>
                    </>
                    }
                    {!showHeader && <>
                        <Component {...pageProps} />
                    </>
                    }
                </Provider>
            </RecoilRoot>
        </ErrorBoundary>
    </>
}

if (isBrowser && "serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/sw.js")
            .catch((err) => {
                console.log("Service worker registration failed", err);
            });
    });
}

export default DelForno

const HeaderContainer = styled.div`
    ${tw`w-full fixed z-[100]`}
`;

const FooterContainer = styled.div`
    ${tw`w-full fixed left-0 bottom-0`}
`;

const PageContainer = styled.div`
    ${tw`flex flex-col h-screen justify-between items-start`}
`;

const BodyContainer = styled.div`
    ${tw`w-full`}
`;