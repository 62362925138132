import { initializeApp, getApps, getApp } from "firebase/app";
import localforage from "localforage";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import Datalayer from "./datalayer";

const firebaseConfig = {
    apiKey: "AIzaSyAHRgoYT3iWoYKzMeRD5S93uTwnq9Bjpxw",
    authDomain: "delfornopush.firebaseapp.com",
    projectId: "delfornopush",
    storageBucket: "delfornopush.appspot.com",
    messagingSenderId: "875149161780",
    appId: "1:875149161780:web:e018b3538c5d67ad10a161",
    measurementId: "G-G9H2XWJWC3"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const firebaseCloudMessaging = {
    tokenInlocalforage: async () => {
        const token = await localforage.getItem("fcm_token");
        return token;
    },
    onMessage: async () => {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
            };
            // if ('serviceWorker' in navigator) {
            //     navigator.serviceWorker.ready.then(function(registration) {
            //         registration.showNotification(notificationTitle, notificationOptions);
            //     });
            // }
        });
    },
    subscribeToTopic: async (token) => {
        var datalayer = new Datalayer();
        await datalayer.post('notification/subscribe', token);
    },
    init: async function () {
        if (await isSupported()) {
            try {
                const token = await this.tokenInlocalforage();
                if (token) {
                    await this.subscribeToTopic(token);
                }

                const messaging = getMessaging(app);
                await Notification.requestPermission();

                getToken(messaging, { vapidKey: "BK_VwlEQwrZvAwzzJsSnaVACRNHyd2TTjJtH_JePZeOn8BlHnki_rIrNx61hffhddLEL3L6_0NnWGLRNLro0Wf4" }).then((currentToken) => {
                    if (currentToken) {
                        localforage.setItem("fcm_token", currentToken);
                        this.subscribeToTopic(currentToken);
                    } else {
                        console.log("NOTIFICATION, No registration token available. Request permission to generate one.");
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } catch (error) {
                console.error(error);
            }
        }
    }
};

export { firebaseCloudMessaging };
