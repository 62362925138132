import _ from "lodash";
import PouchDB from "pouchdb";

PouchDB.plugin(require('pouchdb-upsert'));

const db = new PouchDB('delforno', { revs_limit: 1, auto_compaction: true });

const remoteDB = new PouchDB('https://couch.delforno.co.za/delforno/', {
    auth: {
        username: "web",
        password: "READONLY"
    }
});

export default class Database {

    constructor() {

    }

    replicateDatabase() {
        db.replicate.from(remoteDB, { retry: true }) //, live: true
            .on('change', function (info) {
           //     console.log('changed', info);
            }).on('paused', function (err) {
           //    console.log('paused always', err);
            }).on('active', function () {
            //    console.log('active');
            }).on('denied', function (err) {
            //    console.log('denied', err);
            }).on('complete', function (info) {
            //   console.log('complete', info);
            }).on('error', function (err) {
              //  console.log('error', err);
            });

        this.checkDatabase();
        //console.log('replicating')
    }

    getDatabase() {
        return db;
    }

    destroyDatabase() {

        db.destroy().then(function (response) {
         //   console.log('Database destroyed');
        }).catch(function (err) {
       //     console.log('Error destroying database', err);
        });

    }

    putCustomer() {
        
        var customer = {
            "_id": 'customer',
            "firstname": "",
            "surname": "",
            "email": "",
            "mobile": "",
            "deliveryAddress": {},
            "store": {},
            "region": "GP",
            "isDelivery": false,
            "isCollection": false,
            "isHalaal": false
        };

        db.putIfNotExists('customer', customer).catch(function (err) {
         //   console.log('Error creating customer', err);
        });

    }

    putHistory() {

        var history = {
            "_id": "history",
            "carts": []
        }

        db.putIfNotExists('history', history).catch(function (err) {
          //  console.log('Error creating history', err);
        });

    }

    putBasePrices() {

        var baseprices = {
            "_id": "baseprices",
            "priceset": "BASE",
            "menuitems": []
        }

        db.putIfNotExists('baseprices', baseprices).catch(function (err) {
            console.log('Error creating base prices', err);
        });

    }

    putBaseIngredients() {

        var baseingredients = {
            "_id": "baseingredients",
            "priceset": "BASEINGREDIENTS",
            "ingredients": []
        }

        db.putIfNotExists('baseingredients', baseingredients).catch(function (err) {
          //  console.log('Error creating base ingredients', err);
        });

    }

    putBaseOptions() {

        var baseoptions = {
            "_id": "baseoptions",
            "priceset": "BASEOPTIONS",
            "menuoptions": []
        }

        db.putIfNotExists('baseoptions', baseoptions).catch(function (err) {
           // console.log('Error creating base options', err);
        });
    }

    putBaseSpecials() {

        var basespecials = {
            "_id": "basespecials",
            "priceset": "BASESPECIALS",
            "menuspecials": []
        }

        db.putIfNotExists('basespecials', basespecials).catch(function (err) {
           // console.log('Error creating base options', err);
        });
    }

    putCart() {

        let isoCreated = new Date().toISOString().replace('Z', '');

        var cart = {
            "_id": 'cart',
            "qty": 0,
            "total": 0,
            "dough": 0,
            "items": [],
            "paymentType": 1,
            "instructions": '',
            "specials": [],
            "promotion": {},
            "customerOrderId": 0,
            "created": isoCreated
        };

        db.putIfNotExists('cart', cart).catch(function (err) {
          //  console.log('Error creating cart', err);
        });

    }

    putMenuItems() {

        var menuitems = {
            "_id": "menitems",
            "priceset": "GP",
            "menuitems": []
        }

        db.putIfNotExists('menuitems', menuitems).catch(function (err) {
         //   console.log('Error creating menu items', err);
        });

    }

    putStores() {

        var stores = {
            "_id": "stores",
            "stores": []
        }

        db.putIfNotExists('stores', stores).catch(function (err) {
         //   console.log('Error creating stores', err);
        });

    }

    putMenuCategories() {

        var categories = {
            "_id": "menucategories",
            "menucategories": []
        }

        db.putIfNotExists('categories', categories).catch(function (err) {
         //   console.log('Error creating categories', err);
        });

    }

    putMenuSubCategories() {

        var subcategories = {
            "_id": "subcategories",
            "menusubcategories": []
        }

        db.putIfNotExists('subcategories', subcategories).catch(function (err) {
         //   console.log('Error creating sub categories', err);
        });

    }

    getMenuItems() {
        return db.get('menuitems');
    }

    checkDatabase() {

        let isValid = false;

        // db.allDocs({conflicts: true}).then((res) => {
        //     console.log(res)
        // }).catch((err) => {
        //     console.log(err)
        // });

        db.get('menuitems', {rev: true}).then(async (doc) => {

            //console.log(doc)

            if (doc.menuitems.length === 0) {
                remoteDB.get('menuitems').then((doc) => {
               //     console.log('remote', doc)
                    db.put(doc).then((res) => {
            //            console.log('Menu items updated', res);
                    }).catch((err) => { });

                }).catch((err) => {
          //          console.log('Error getting menu items', err);
                });
            }
        }).catch((err) => { });


        return isValid;

    }

    // async triggerSync() {

    //     let res = await axios.get('https://couch.delforno.co.za:4000/delforno/revisions', {
    //         auth: {
    //             username: 'admin',
    //             password: 'A523a6ab!!'
    //         }
    //     });

    //     if (res.status === 200) {
    //         const options = {
    //             url: 'https://couch.delforno.co.za:4000/delforno/revisions',
    //             method: 'PUT',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json;charset=UTF-8'
    //             },
    //             data: res.data,
    //             auth: {
    //                 username: 'admin',
    //                 password: 'A523a6ab!!'
    //             }
    //         };

    //         axios(options)
    //             .then(response => {
    //                 console.log(response);
    //             });
    //     }
    // }
}