import { useState, useEffect } from "react";
import _ from "lodash";
import isToday from "date-fns/isToday";
import PouchDB from "pouchdb";
PouchDB.plugin(require('pouchdb-upsert'));
import { v4 as uuidv4 } from 'uuid';
import { config } from "../constants";

const localDB = new PouchDB('delforno', { revs_limit: 1 });

let listeners = [];
let state = {
    cart: {
        qty: 0,
        total: 0,
        instructions: '',
        items: []
    }
};

const finalizeCart = async (customerOrderId, delay) => {

    const doc = await localDB.get('history');
    let cart = await getCart();

    cart.customerOrderId = customerOrderId;
    cart.delay = delay;
    cart.date_ordered = new Date();
    let carts = [];
    carts.push(cart);

    _.each(doc.carts, (cart) => {
        carts.push(cart);
    })

    localDB.upsert('history', (doc) => {
        doc.carts = carts;
        return doc;
    }).catch(function (err) {
        console.log('Error persisting history:', err)
    });

    clearCart();
}

const getCart = async () => {
    try {
        const cart = await localDB.get('cart');
        return cart;
    } catch (err) {
        return null;
    }
}

const getSpecials = async () => {

    const doc = await localDB.get('menuspecials');

    // const doc = {
    //     "_id": "menuspecials",
    //     "_rev": "59-322ec6c10bb9429c9d6edb3afcea0716",
    //     "menuspecials": [
    //         {
    //             "menuSpecialId": 11,
    //             "specialName": "Two + Two Special + Free 1.5l Drink",
    //             "specialType": 6,
    //             "specialDescription": "Two Original Pizzas + 2 Traditional Pastas + 1.5l Drink for R369",
    //             "categoryId": [1, 2],
    //             "subCategoryId": [1, 4],
    //             "size": 1,
    //             "quantity": [2, 2],
    //             "grouping": [[1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15], [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 135, 301, 302, 303]],
    //             "freeItemId": "0",
    //             "freeItemCategoryId": 7,
    //             "freeItemSwaps": "94,95,101,107",
    //             "freeItemQty": 1,
    //             "freeItemSize": 1,
    //             "price": 369,
    //             "isHidden": false,
    //             "slug": "two-plus-two-special-free-15l-drink",
    //         }
    //     ]
    // }

    return doc.menuspecials;
}

const clearCart = () => {
    state.cart.items = [];
    state.cart.qty = 0;
    state.cart.total = 0;
    state.cart.instructions = '';
    state.cart.specials = [];
    state.cart.promotion = {};
    persistCart(state.cart)
}

const clearOldCart = async () => {

    let cart = await getCart();

    if (cart?.created !== undefined) {
        let today = isToday(new Date(cart.created));
        if (!today) {
            state.cart.items = [];
            state.cart.qty = 0;
            state.cart.total = 0;
            state.cart.instructions = '';
            state.cart.specials = [];
            state.cart.promotion = {};
            persistCart(state.cart)
        }
    }
}

async function addFreeEasterItem() {

    let freeitems = [288, 289, 290, 291, 94, 95, 101, 107, 90];
    //let freeitems = [94, 95, 101, 107];
    let _item = _.sample(freeitems);

    const doc = await localDB.get('menuitems');
    let item = _.filter(doc.menuitems, { menuItemId: _item })[0];

    let size = 1;
    let mealsize = "";

    if (_item === 94 || _item === 95 || _item === 101 || _item === 107) {
        size = 0;
        mealsize = " 300ml";
    }

    item.base = {
        qty: 1,
        size: size,
        total: 0,
        optionsCost: 0,
        ingredientsCost: 0
    }

    item.name = item.name + mealsize + ' (Free Easter Egg)';
    item.options = [];
    item.selectedingredients = [];
    item.added = [];
    item.removed = [];
    item.isRemovable = true;
    item.swaps = [];

    addCartItem(item, 1);

}

async function addDriversTip(amount) {

    let cart = await getCart();
    let tip = _.find(cart.items, { 'menuItemId': -2 });
    let guid = uuidv4();

    if (tip) {
        tip.base.total = amount;
        tip.priceLarge = amount;
    } else {
        cart.items.push({
            menuItemId: -2,
            name: 'Drivers Tip',
            menuCategory: 'Other',
            menuCategoryId: 10000,
            options: [],
            removed: [],
            added: [],
            isRemovable: false,
            priceLarge: amount,
            base: { qty: 0, size: 1, total: amount, appliedSpecialId: 0, optionsCost: 0, ingredientsCost: 0 },
            uid: guid
        });
    }

    cart.total = calculateTotal(cart);
    persistCart(cart);
    return cart;

}

const reorderCart = async (items) => {

    getCart().then(async (cart) => {

        _.each(items, (item) => {
            let _item = _.cloneDeep(item);
            let guid = uuidv4();
            _item.uid = guid;
            cart.items.push(_item);
        });

        let cartWithSpecials = await checkAllSpecials(cart);
        let cartWithDelivery = await checkCartDelivery(cartWithSpecials);
        await persistCart(cartWithDelivery);

    });
}

const applyDelFornoDough = async (value) => {
    getCart().then(async (cart) => {
        cart.dough = value;
        await persistCart(cart);
    });
}

const addCartItem = async (item, qty) => {

    getCart().then(async (cart) => {

        //older original carts may not have a created date
        if (cart.created === undefined) {
            cart.created = new Date().toISOString().replace('Z', '');
        }

        for (var i = 0; i < qty; i++) {
            let _item = _.cloneDeep(item);
            let guid = uuidv4();
            _item.uid = guid;
            cart.items.push(_item);
        }

        let cartWithSpecials = await checkAllSpecials(cart);
        let cartWithDelivery = await checkCartDelivery(cartWithSpecials);
        await persistCart(cartWithDelivery);

    });

}

const updateCartSpecials = async () => {
    getCart().then(async (cart) => {
        let cartWithSpecials = await checkAllSpecials(cart);
        let cartWithDelivery = await checkCartDelivery(cartWithSpecials);
        await persistCart(cartWithDelivery);

    });
}

const addCartPromotion = async (promotion) => {

    console.log('Promo:', promotion)

    let cart = await getCart();
    let found = _.find(cart.items, { 'menuItemId': -3 });

    if (found) { //Remove any existing promotion codes
        cart = await removeCartItem(found.uid);
    }

    let guid = uuidv4();

    //Remove any other specials
    cart.specials = [];

    //and apply promotion
    cart.promotion = promotion;

    //Get type of promotion

    //Minimum spend
    if (promotion.minimumSpend > 0) {

        let discount = 0;
        let total = cart.total;

        if (total >= promotion.minimumSpend) {
            if (promotion.discountPercentage > 0) { //percentage discount
                discount = (promotion.discountPercentage / 100) * total;
            }
            if (promotion.discountAmount > 0) { //percentage discount
                discount = promotion.discountAmount;
            }
        }

        cart.items.push({
            menuItemId: -3,
            name: 'Promotion Code ' + promotion.code,
            menuCategory: 'Other',
            menuCategoryId: 10000,
            options: [],
            removed: [],
            added: [],
            isRemovable: true,
            priceLarge: -Math.ceil(discount),
            base: { qty: 0, size: 1, total: -Math.ceil(discount), appliedSpecialId: 0, optionsCost: 0, ingredientsCost: 0 },
            uid: guid
        });

        // and remove any freebies added by specials. This must happen after the promotion code has been added
        let freebies = _.filter(cart.items, { 'isFree': true });
        _.each(freebies, (freebie) => {
            _.remove(cart.items, _item => _item.uid === freebie.uid);
        });

        persistCart(cart);
        return cart;
    }

    //Discount Amount
    if (promotion.discountAmount > 0) {

        cart.items.push({
            menuItemId: -3,
            name: 'Promotion Code ' + promotion.code,
            menuCategory: 'Other',
            menuCategoryId: 10000,
            options: [],
            removed: [],
            added: [],
            isRemovable: true,
            priceLarge: -Math.abs(promotion.discountAmount),
            base: { qty: 0, size: 1, total: -Math.abs(promotion.discountAmount), appliedSpecialId: 0, optionsCost: 0, ingredientsCost: 0 },
            uid: guid
        });

        // and remove any freebies added by specials. This must happen after the promotion code has been added
        let freebies = _.filter(cart.items, { 'isFree': true });
        _.each(freebies, (freebie) => {
            _.remove(cart.items, _item => _item.uid === freebie.uid);
        });

        console.log(cart)

        persistCart(cart);
        return cart;
    }

    //Discount Percentage
    if (promotion.discountPercentage > 0) {

        let discount = 0;
        let total = cart.total;

        discount = (promotion.discountPercentage / 100) * total;

        cart.items.push({
            menuItemId: -3,
            name: 'Promotion Code ' + promotion.code,
            menuCategory: 'Promotion',
            menuCategoryId: 10000,
            options: [],
            removed: [],
            added: [],
            isRemovable: true,
            priceLarge: -Math.ceil(discount),
            base: { qty: 0, size: 1, total: -Math.ceil(discount), appliedSpecialId: 0, optionsCost: 0, ingredientsCost: 0 },
            uid: guid
        });

        // and remove any freebies added by specials. This must happen after the promotion code has been added
        let freebies = _.filter(cart.items, { 'isFree': true });
        _.each(freebies, (freebie) => {
            _.remove(cart.items, _item => _item.uid === freebie.uid);
        });

        persistCart(cart);
        return cart;
    }
}

const setCartOrderId = async (customerOrderId) => {
    let cart = await getCart();
    cart.customerOrderId = customerOrderId;
    persistCart(cart);
}

const swapFreebie = async (original, swap) => {

    const doc = await localDB.get('menuitems');
    let _item = _.filter(doc.menuitems, { menuItemId: swap })[0];
    let guid = uuidv4();
    let cart = await getCart();
    let _cart = _.cloneDeep(cart);

    let freebie = {
        menuItemId: _item.menuItemId,
        menuCategoryId: _item.menuCategoryId,
        menuCategory: _item.menuCategory,
        menuSubCategoryId: _item.menuSubCategoryId,
        menuSubCategory: _item.menuSubCategory,
        name: _item.name,
        halaalName: _item.halaalName,
        description: _item.description,
        halaalDescription: _item.halaalDescription,
        priceSmall: _item.priceSmall,
        priceLarge: _item.priceLarge,
        ingredients: _item.ingredients,
        options: [],
        halaalIngredients: _item.halaalIngredients,
        isVegetarian: _item.isVegetarian,
        isHot: _item.isHot,
        isNew: _item.isNew,
        isNuts: _item.isNuts,
        isBanting: _item.isBanting,
        isGlutenFree: _item.isGlutenFree,
        isHalaal: _item.isHalaal,
        isEnabled: _item.isEnabled,
        isDeleted: _item.isDeleted,
        base: {
            qty: original.base.qty,
            size: original.base.size,
            total: 0,
            optionsCost: 0,
            ingredientsCost: 0
        },
        selectedingredients: [],
        added: [],
        removed: [],
        isRemovable: false,
        isFree: true,
        swaps: original.swaps,
        uid: guid,
        sizeText: _item.sizeText
    }

    _.each(_cart.specials, (special) => {
        if (special.specialType === 5 || special.specialType === 3) {
            const index = special.items.indexOf(original.uid);
            if (index !== -1) {
                special.items[index] = guid;
            }
        }
    });

    _.remove(_cart.items, _item => _item.uid === original.uid);
    _cart.items.push(freebie);
    persistCart(_cart);
}

const checkAllSpecials = async (cart) => {

    if (cart && cart.items && cart.items.length > 0) {

        const specials = await getSpecials();

        if (cart.specials.length > 0) {

            const customer = await localDB.get('customer');
            let storeId = 0;

            if (customer) {
                storeId = customer.store.storeId;
            }

            _.each(cart.specials, (special) => {
                if (special.isStoreSpecial) {
                    let _found = _.find(specials, { 'menuSpecialId': special.specialId });
                    let _stores = _found.stores.split(',').map(Number);
                    if (_stores.indexOf(storeId) === -1) {
                        _.remove(cart.specials, _special => _special.specialId === special.specialId);
                        _.remove(cart.items, _item => _item.isFree === true);
                    }
                }
            });
        }

        let _specials = _.orderBy(specials, ['weekDay'], ['desc']);
        _specials = _.filter(_specials, { 'isHidden': false });

        _.each(_specials, async (special) => {
            await checkSpecials(cart, special, specials);
        });

        return cart;
    }
}

const checkSpecials = async (cart, special) => {

    const fetchServerTime = async () => {
        try {
            const response = await fetch(process.env.NEXT_PUBLIC_API_URL + '/api/time/current-time');
            const data = await response.json();
            return new Date(data.serverTime);
        } catch (error) {
            console.error('Error fetching server time:', error);
            return null;
        }
    };

    function convertToLocalTime(date, offset) {
        const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        const localDate = new Date(utc + (3600000 * offset));
        return localDate;
    }

    const serverTime = await fetchServerTime();
    if (!serverTime) {
        console.log('Unable to fetch server time');
        return false;
    }

    const localTime = convertToLocalTime(serverTime, 2); // GMT+2
    const currentDay = localTime.getDay();

    const isSpecialApplicable = (special) => {
        // Check if the special is available on the current day
        const daysAvailable = special.weekDay.split(',').map(Number);
        if (!daysAvailable.includes(currentDay)) {
            return false; // Special does not apply on the current day
        }

        // If startTime and endTime are defined, check if the current time is within the range
        if (special.startTime && special.endTime) {
            const [startHours, startMinutes] = special.startTime.split(':').map(Number);
            const [endHours, endMinutes] = special.endTime.split(':').map(Number);
            const startTime = new Date(localTime);
            startTime.setHours(startHours, startMinutes, 0, 0);
            const endTime = new Date(localTime);
            endTime.setHours(endHours, endMinutes, 0, 0);

            if (!(localTime >= startTime && localTime <= endTime)) {
                return false; // Special does not apply at the current time
            }
        }

        return true; // Special is applicable
    };

    let itemscheck = [];
    _.each(cart.items, (item) => {

        if (item.menuItemId === -3) { // The cart already has a promotion code applied to it and is not allowed other discounts
            return false;
        }

        itemscheck.push(item.uid);
    });

    let inspecials = [];
    if (cart.specials.length > 0) {
        _.each(cart.specials, (special) => {
            inspecials.push(...special.items);
        });
    }

    itemscheck = itemscheck.filter(x => inspecials.indexOf(x) === -1);


    if (isSpecialApplicable(special)) {
        let items = [];
        _.each(cart.items, (item) => { //Check that the items match the special size and are not applied to a special 
            if ((itemscheck.indexOf(item.uid) > -1) && (item.base.size === special.size) && item.menuItemId > 0) {
                items.push(item);
            }
        });

        if (items.length > 0) { //Check if it falls into the special

            if (special.specialType === 6) {
                createMultiItemSpecial(cart, special, items);
            } else {

                let existing = _.map(items, 'menuItemId');
                let grouping = special.grouping.split(',').map(Number);

                let match = _.intersection(existing, grouping);

                if (!match) { //Doesn't match this special
                    return null;
                }

                let specialQty = special.quantity;
                let currentQty = 0;
                let filtered = [];

                _.each(match, (id) => {
                    let found = _.filter(items, { 'menuItemId': id });
                    _.each(found, (item) => {
                        filtered.push(item);
                        currentQty++;
                    });
                });

                if (currentQty >= specialQty) { //Lets create a special

                    let created = {
                        specialId: special.menuSpecialId,
                        specialName: special.specialName,
                        specialType: special.specialType,
                        items: [],
                        total: 0
                    }

                    if (special.specialType === 1) { //This is a price special

                        const customer = await localDB.get('customer');
                        let storeId = 0;

                        if (customer) {
                            storeId = customer.store.storeId;
                        }

                        if (special.isStoreSpecial && storeId !== 0) { //checking if this is a store only special
                            let _stores = special.stores.split(',').map(Number);
                            if (_stores.indexOf(storeId) === -1) {
                                return null;
                            }
                        }

                        var qtyCounter = 0;
                        _.each(filtered, (item) => {
                            if (qtyCounter < specialQty) {
                                created.items.push(item.uid);
                                created.total = special.price;
                            }
                            qtyCounter++;
                        });

                        cart.specials.push(created);
                        persistCart(cart); //Todo: Overkill but causing issues with promotions see also other special types
                        if (currentQty > specialQty) {
                            checkAllSpecials(cart);
                        }
                    }

                    if (special.specialType === 3) { //This is a price and freebie special]

                        const customer = await localDB.get('customer');
                        let storeId = 0;

                        if (customer) {
                            storeId = customer.store.storeId;
                        }

                        if (special.isStoreSpecial && storeId !== 0) { //checking if this is a store only special
                            let _stores = special.stores.split(',').map(Number);
                            if (_stores.indexOf(storeId) === -1) {
                                return null;
                            }
                        }

                        // Check how many times this special can be applied

                        if (special.maxPerOrder > 0) {
                            const maxApplications = special.maxPerOrder || 1; // Assuming maxApplications is part of special config
                            let appliedCount = cart.specials.filter(s => s.specialId === special.menuSpecialId).length;
                            console.log('applied:', appliedCount)
                            if (appliedCount >= maxApplications) {
                                return null; // Do not apply if limit is reached
                            }
                        }

                        var qtyCounter = 0;
                        _.each(filtered, (item) => {
                            if (qtyCounter < specialQty) {
                                created.items.push(item.uid);
                                created.total = special.price;
                            }
                            qtyCounter++;
                        });

                        const doc = await localDB.get('menuitems');
                        let items = special.freeItemSwaps.split(",").map(Number);
                        let guid = uuidv4();

                        let _item = _.filter(doc.menuitems, { menuItemId: items[0] })[0];
                        let freebie = {
                            menuItemId: _item.menuItemId,
                            menuCategoryId: _item.menuCategoryId,
                            menuCategory: _item.menuCategory,
                            menuSubCategoryId: _item.menuSubCategoryId,
                            menuSubCategory: _item.menuSubCategory,
                            name: _item.name,
                            halaalName: _item.halaalName,
                            description: _item.description,
                            halaalDescription: _item.halaalDescription,
                            priceSmall: _item.priceSmall,
                            priceLarge: _item.priceLarge,
                            ingredients: _item.ingredients,
                            options: [],
                            halaalIngredients: _item.halaalIngredients,
                            isVegetarian: _item.isVegetarian,
                            isHot: _item.isHot,
                            isNew: _item.isNew,
                            isNuts: _item.isNuts,
                            isBanting: _item.isBanting,
                            isGlutenFree: _item.isGlutenFree,
                            isHalaal: _item.isHalaal,
                            isEnabled: _item.isEnabled,
                            isDeleted: _item.isDeleted,
                            base: {
                                qty: special.freeItemQty,
                                size: special.freeItemSize,
                                total: 0,
                                optionsCost: 0,
                                ingredientsCost: 0
                            },
                            selectedingredients: [],
                            added: [],
                            removed: [],
                            isRemovable: false,
                            isFree: true,
                            swaps: items,
                            uid: guid,
                            sizeText: _item.sizeText
                        }

                        created.items.push(freebie.uid);
                        created.specialType = special.specialType;
                        created.isStoreSpecial = (special.isStoreSpecial) ? true : false;

                        cart.items.push(freebie);
                        cart.specials.push(created);

                        persistCart(cart); //Todo: Overkill but causing issues with promotions see also other special types
                        if (currentQty > specialQty) {
                            checkAllSpecials(cart);
                        }
                    }


                    if (special.specialType === 5) { //This is a freebie we need to ask the customer what freebie they want NEW VERSION

                        const customer = await localDB.get('customer');
                        let storeId = 0;

                        if (customer) {
                            storeId = customer.store.storeId;
                        }

                        if (special.isStoreSpecial && storeId !== 0) { //checking if this is a store only special
                            let _stores = special.stores.split(',').map(Number);
                            if (_stores.indexOf(storeId) === -1) {
                                return null;
                            }
                        }

                        const doc = await localDB.get('menuitems');
                        let items = special.freeItemSwaps.split(",").map(Number);
                        let guid = uuidv4();

                        if (items.length > 0) {

                            let _item = _.filter(doc.menuitems, { menuItemId: items[0] })[0];
                            let freebie = {
                                menuItemId: _item.menuItemId,
                                menuCategoryId: _item.menuCategoryId,
                                menuCategory: _item.menuCategory,
                                menuSubCategoryId: _item.menuSubCategoryId,
                                menuSubCategory: _item.menuSubCategory,
                                name: _item.name,
                                halaalName: _item.halaalName,
                                description: _item.description,
                                halaalDescription: _item.halaalDescription,
                                priceSmall: _item.priceSmall,
                                priceLarge: _item.priceLarge,
                                ingredients: _item.ingredients,
                                options: [],
                                halaalIngredients: _item.halaalIngredients,
                                isVegetarian: _item.isVegetarian,
                                isHot: _item.isHot,
                                isNew: _item.isNew,
                                isNuts: _item.isNuts,
                                isBanting: _item.isBanting,
                                isGlutenFree: _item.isGlutenFree,
                                isHalaal: _item.isHalaal,
                                isEnabled: _item.isEnabled,
                                isDeleted: _item.isDeleted,
                                base: {
                                    qty: special.freeItemQty,
                                    size: special.freeItemSize,
                                    total: 0,
                                    optionsCost: 0,
                                    ingredientsCost: 0
                                },
                                selectedingredients: [],
                                added: [],
                                removed: [],
                                isRemovable: false,
                                isFree: true,
                                swaps: items,
                                uid: guid,
                                sizeText: _item.sizeText
                            }

                            var qtyCounter = 0;
                            _.each(filtered, (item) => {
                                if (qtyCounter < specialQty) {
                                    created.items.push(item.uid);
                                }
                                qtyCounter++;
                            });

                            created.items.push(freebie.uid);
                            created.total = 0;
                            created.specialType = special.specialType;
                            created.isStoreSpecial = (special.isStoreSpecial) ? true : false;

                            cart.items.push(freebie);
                            cart.specials.push(created);
                            persistCart(cart); //Todo: Overkill but causing issues with promotions see also other special types

                            if (currentQty > specialQty) {
                                checkAllSpecials(cart);
                            }
                        }
                    }
                }
            }
        }
    }
}

const createMultiItemSpecial = async (cart, special, items) => {

    let existing = _.map(items, 'menuItemId');
    let grouping = special.grouping[0].concat(special.grouping[1]);
    let match = _.intersection(existing, grouping);

    if (!match) { //Doesn't match this special
        return null;
    }

    let group1 = special.grouping[0];
    let group2 = special.grouping[1];

    let group1Qty = special.quantity[0];
    let group2Qty = special.quantity[1];

    let currentQty1 = 0;
    let currentQty2 = 0;
    let filtered = [];

    _.each(match, (id) => {

        let existsA = group1.find((x) => x === id);
        if (existsA) {
            let found = _.filter(items, { 'menuItemId': id });
            _.each(found, (item) => {
                filtered.push(item);
                currentQty1++;
            });
        }

        let existsB = group2.find((x) => x === id);
        if (existsB) {
            let found = _.filter(items, { 'menuItemId': id });
            _.each(found, (item) => {
                filtered.push(item);
                currentQty2++;
            });
        }

    });

    if ((currentQty1 >= group1Qty) && (currentQty2 >= group2Qty)) {

        let created = {
            specialId: special.menuSpecialId,
            specialName: special.specialName,
            specialType: special.specialType,
            items: [],
            total: special.price
        }

        const doc = await localDB.get('menuitems');
        let items = special.freeItemSwaps.split(",").map(Number);
        let guid = uuidv4();

        if (items.length > 0) {

            let _item = _.filter(doc.menuitems, { menuItemId: items[0] })[0];
            let freebie = {
                menuItemId: _item.menuItemId,
                menuCategoryId: _item.menuCategoryId,
                menuCategory: _item.menuCategory,
                menuSubCategoryId: _item.menuSubCategoryId,
                menuSubCategory: _item.menuSubCategory,
                name: _item.name,
                halaalName: _item.halaalName,
                description: _item.description,
                halaalDescription: _item.halaalDescription,
                priceSmall: _item.priceSmall,
                priceLarge: _item.priceLarge,
                ingredients: _item.ingredients,
                options: [],
                halaalIngredients: _item.halaalIngredients,
                isVegetarian: _item.isVegetarian,
                isHot: _item.isHot,
                isNew: _item.isNew,
                isNuts: _item.isNuts,
                isBanting: _item.isBanting,
                isGlutenFree: _item.isGlutenFree,
                isHalaal: _item.isHalaal,
                isEnabled: _item.isEnabled,
                isDeleted: _item.isDeleted,
                base: {
                    qty: special.freeItemQty,
                    size: special.freeItemSize,
                    total: 0,
                    optionsCost: 0,
                    ingredientsCost: 0
                },
                selectedingredients: [],
                added: [],
                removed: [],
                isRemovable: false,
                isFree: true,
                swaps: items,
                uid: guid,
                sizeText: _item.sizeText
            }

            var qtyCounter = 0;
            _.each(filtered, (item) => {
                if (qtyCounter < (group1Qty + group2Qty)) {
                    created.items.push(item.uid);
                }
                qtyCounter++;
            });

            created.items.push(freebie.uid);
            created.total = special.price;
            created.specialType = special.specialType;
            created.isStoreSpecial = (special.isStoreSpecial) ? true : false;

            cart.items.push(freebie);
            cart.specials.push(created);
            persistCart(cart); //Todo: Overkill but causing issues with promotions see also other special types

            if ((currentQty1 > group1Qty) && (currentQty2 > group2Qty)) {
                checkAllSpecials(cart);
            }
        }
    }
}

const addressChange = async () => {

    let cart = await getCart();
    let cartWithSpecials = await checkAllSpecials(cart);
    let cartWithDelivery = await checkCartDelivery(cartWithSpecials);
    await persistCart(cartWithDelivery);

}

const checkFreeDeliverySpecial = async (store) => {
    const specials = await getSpecials();
    const result = specials.some((special) => {
        if (special.menuSpecialId === 999) {
            const storesArray = special.stores.split(",").map(Number);
            if (storesArray.includes(store.storeId)) {
                return true;
            }
            return false;
        }
        return false;
    });

    return result;
}

const checkCartDelivery = async (cart) => {

    let deliverycharge = config.deliverycharge;
    let guid = uuidv4();

    if (cart) {
        return localDB.get('customer').then(async (doc) => {

            let isfreedelivery = await checkFreeDeliverySpecial(doc.store);
            let charge = _.find(cart.items, { 'menuItemId': -1 });
            let tip = _.find(cart.items, { 'menuItemId': -2 });

            if (doc && doc.isDelivery) { //Add charge if it doesn't exists and is delivery

                if (!charge) {

                    let chargeName = 'Delivery Charge';

                    if (isfreedelivery) {
                        deliverycharge = 0;
                        chargeName = 'Free Delivery';
                    } else {
                        deliverycharge = config.deliverycharge;
                        chargeName = 'Delivery Charge';
                    }

                    cart.items.push({
                        menuItemId: -1,
                        name: chargeName,
                        menuCategory: 'Other',
                        menuCategoryId: 10000,
                        options: [],
                        removed: [],
                        added: [],
                        isRemovable: false,
                        priceLarge: deliverycharge,
                        base: { qty: 0, size: 1, total: deliverycharge, appliedSpecialId: 0, optionsCost: 0, ingredientsCost: 0 },
                        uid: guid
                    });
                } else {

                    if (isfreedelivery) {
                        charge.name = 'Free Delivery';
                        charge.base.total = 0;
                        charge.priceLarge = 0;
                    } else {
                        charge.name = 'Delivery Charge';
                        charge.base.total = deliverycharge;
                        charge.priceLarge = deliverycharge;
                    }
                }
            }

            if (doc && doc.isCollection && charge) { //Remove charge and tip if it does exist and collection

                if (charge) {
                    removeCartItem(charge.uid);
                }

                if (tip) {
                    removeCartItem(tip.uid);
                }

            }

            return cart;

        });
    }

}

const removeCartItem = async (uid) => {

    let cart = await getCart();
    let _cart = _.cloneDeep(cart);

    //Remove the cart promotions if this is a promotion item you are removing
    let toremove = _.filter(_cart.items, { 'uid': uid });
    if (toremove[0].menuItemId === -3) {
        _cart.promotion = {};
    }

    //Remove the item
    _.remove(_cart.items, _item => _item.uid === uid);

    //check if only "other" items are left in the cart and if so clear it. They always have negative menuItemIds

    let total = 0;

    _.each(_cart.items, (item) => {
        total += parseInt(item.menuItemId);
    });

    if (total < 0) {
        clearCart();
    } else {

        _cart.specials = []; //reset all specials

        let freebies = _.filter(_cart.items, { 'isFree': true });
        _.each(freebies, (freebie) => {
            _.remove(_cart.items, _item => _item.uid === freebie.uid);
        });

        await checkAllSpecials(_cart);

        _cart.qty = calculateQty(_cart);
        _cart.total = calculateTotal(_cart);
        persistCart(_cart);
        return _cart;
    }

}

function calculateQty(cart) {

    if (cart) {
        let qty = 0;

        _.each(cart.items, (item) => {
            qty += item.base.qty;
        });
        return qty;
    }

}

function calculateTotal(cart) {

    let total = 0;
    let items = []

    //calculate specials
    _.each(cart.specials, (special) => {
        if (special.specialType === 1 || special.specialType === 3 || special.specialType === 6) { //Price special
            items.push(...special.items);
            total += special.total;
        }
    });

    //calculate non special items

    _.each(cart.items, (item) => {
        if (items.indexOf(item.uid) === -1) {
            total += item.base.total;
        } else {
            total += (item.base.optionsCost + item.base.ingredientsCost); //need to include extras on specials
        }
    });

    if (total < 1) {
        total = 0;
    }

    return total;
}

const persistCart = async (cart) => {

    if (cart) {
        cart.qty = calculateQty(cart);
        cart.total = calculateTotal(cart);
        actions.setCart(cart);

        await localDB.upsert('cart', (doc) => {
            doc.qty = cart.qty;
            doc.dough = cart.dough;
            doc.total = cart.total;
            doc.items = cart.items;
            doc.instructions = cart.instructions;
            doc.specials = cart.specials;
            doc.promotion = cart.promotion;
            doc.customerOrderId = cart.customerOrderId ? cart.customerOrderId : 0;
            doc.created = cart.created;
            return doc;
        }).catch(function (err) {
            console.log(err);
        });
    }
}

const actions = {
    setCartOrderId: (orderId) => {
        let cart = setCartOrderId(orderId);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    applyDelFornoDough: (amount) => {
        let cart = applyDelFornoDough(amount);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    setCart: (cart) => {
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    addCartItem: (item, qty) => {
        let cart = addCartItem(item, qty);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    updateCartSpecials: () => {
        let cart = updateCartSpecials();
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    addFreeEasterItem: () => {
        let cart = addFreeEasterItem();
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    addressChange: () => {
        let cart = addressChange();
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    addDriversTip: (amount) => {
        let cart = addDriversTip(amount);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    removeCartItem: (item) => {
        let cart = removeCartItem(item);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    reorderCart: (items) => {
        let cart = reorderCart(items);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    clearCart: () => {
        let cart = clearCart();
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    clearOldCart: () => {
        let cart = clearOldCart();
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    swapFreebie: (original, swap) => {
        let cart = swapFreebie(original, swap);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    finalizeCart: (customerOrderId, delay) => {
        let cart = finalizeCart(customerOrderId, delay);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    },
    addCartPromotion: (promotion) => {
        let cart = addCartPromotion(promotion);
        state = { ...state, cart };
        for (const listener of listeners) {
            listener(state);
        }
    }
};

export const useCustomState = () => {
    const setState = useState(state)[1];

    useEffect(() => {
        listeners.push(setState);

        return () => {
            listeners = listeners.filter((li) => li !== setState);
        };
    }, [setState]);

    return [state, actions];
};