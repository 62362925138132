import React from "react";
import Image from "next/legacy/image";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import tw, { styled } from "twin.macro";
import Database from "./database";

export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: { message: '', stack: '' },
            info: { componentStack: '' },
            stage: 1
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    async componentDidCatch(error, info) {

        this.setState({
            stage: 2
        });

        let db = new Database();
        db.destroyDatabase();

        setTimeout(() => {
            window.location.href = '/';
        }, 10000);
    }

    
    async rebuildDatabase() {

        this.setState({
            stage: 2
        });

        let db = new Database();
        db.destroyDatabase();

        setTimeout(() => {
            window.location.href = '/';
        }, 10000);

    }

    render() {

        // Check if the error is thrown
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <AddressContainer>
                    <BackgroundContainer style={{}}>
                        <LayoutContainer>
                            <LogoContainer>
                                <FlexCenter>
                                    <Image src={'/logo-black.webp'} width="180" height="120" alt="Del Forno - Pizza, Pasta, Grill" />
                                </FlexCenter>
                            </LogoContainer>
                            <FlexCol>
                                <FlexCenter>
                                    <TextGoodFood className="welcome">An error occurred!</TextGoodFood>
                                </FlexCenter>
                                {this.state.stage === 1 &&
                                    <>
                                        <FlexCenter>
                                            <TextWelcome>The data for this site may be corrupted.</TextWelcome>
                                        </FlexCenter>
                                      </>
                                }
                                {this.state.stage === 2 &&
                                    <ContainerStage1>
                                        <CircleWrapper>
                                            <CountdownCircleTimer
                                                isPlaying
                                                duration={10}
                                                size={100}
                                                colors={["#7CFC00", "#FBCEB1", "#880808"]}
                                                colorsTime={[10, 5, 0]}
                                            >
                                                {({ remainingTime }) => remainingTime}
                                            </CountdownCircleTimer>
                                        </CircleWrapper>
                                        <TextPleaseWait style={{ color: '#fff' }}>Please wait while we rebuild the site database...</TextPleaseWait>
                                    </ContainerStage1>
                                }
                            </FlexCol>
                            {this.state.stage === 1 &&
                                <FlexCenter>
                                    <ButtonRebuild onClick={this.rebuildDatabase.bind(this)}>REBUILD DATABASE</ButtonRebuild>
                                </FlexCenter>
                            }
                        </LayoutContainer>
                    </BackgroundContainer>
                </AddressContainer>


            )
        }

        // Return children components in case of no error

        return this.props.children
    }
}

export default ErrorBoundary;

const CircleWrapper = styled.div`
    ${tw`w-[100px] h-[100px] rounded-[99999px] flex justify-center bg-white`}
`;

const ContainerStage1 = styled.div`
    ${tw`flex flex-col text-center items-center`}
`;

const TextGoodFood = styled.div`
    ${tw`text-white font-medium text-5xl w-72 pb-2 text-center`}
`;

const TextWelcome = styled.div`
    ${tw`leading-4 text-red font-medium pb-2`}
`;

const TextPleaseWait = styled.div`
    ${tw`text-white font-normal w-72 pb-2 pt-2 text-center`}
`;

const ButtonRebuild = styled.button`
    ${tw`leading-4 text-black font-bold pb-2 pt-2 mt-4`}
`;

const TextRebuild = styled.div`
    ${tw`leading-4 text-red font-normal pb-2 pt-4 text-center`}
`;

const FlexCenter = styled.div`
    ${tw`flex justify-center ml-4 mr-4`}
`;

const FlexCol = styled.div`
    ${tw`flex flex-col`}
`;

const AddressContainer = styled.div`
    ${tw`flex justify-center items-center bg-black`}
`;

const BackgroundContainer = styled.div`
    ${tw`w-full h-screen relative`}
`;

const LayoutContainer = styled.div`
    ${tw`flex flex-col h-screen`}
`;

const LogoContainer = styled.div`
    ${tw`flex justify-center pb-8 pt-6`}
`;